import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://fd871c3f5b89427f8fc04a89c66bd693@sentry.io/5187916',
  });
}

const httpLink = createHttpLink({
  uri: 'https://api.dosh.cash/consumer/prod/v1/ugraphql',
});

const authLink = setContext(() => {
  return {
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json; charset=utf-8',
    },
  };
});

ReactGA.initialize([
  {
    trackingId: 'G-5FFMM2665M',
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  },
]);

ReactGA.set('Dosh Travel Bookings');

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);
