import React from 'react';
import Routes from './Routes';

import './styles/fonts.scss';
import './styles/global.css';
import './styles/responsive.css';

const App: React.FunctionComponent = () => {
  return (
    <>
      {/* <div className="body">
        <Routes />
      </div> */}
      <div>
        <h3> The Dosh Travel Program was suspended indefinitely. </h3>
      </div>
    </>
  );
};

export default App;
